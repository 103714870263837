
import { useContext, useEffect, useState } from "react";
import ProductItem from "../../Components/ProductItem";
import { Link, useParams } from "react-router-dom";
import { fetchDataFromApi } from "../../utils/api";
import CircularProgress from '@mui/material/CircularProgress';
import { MyContext } from "../../App";
import axios from "axios";
import { BsEmojiExpressionless } from "react-icons/bs";
import { Button } from "@mui/material";

const Listing2 = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [productView, setProductView] = useState('four');
    const [productData, setProductData] = useState([]);
    const [isLoading, setisLoading] = useState(false);


    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);

        let url = window.location.href;
        let apiEndPoint = "";

        if (url.includes('men')) {
            apiEndPoint = `/api/products?productFor=men`
        }
        if (url.includes('women')) {
            apiEndPoint = `/api/products?productFor=women`
        }
        if (url.includes('kids')) {
            apiEndPoint = `/api/products?productFor=kids`
        }

        setisLoading(true);
        fetchDataFromApi(`${apiEndPoint}`).then((res) => {
            setProductData(res)
            setisLoading(false);
        })
    }, [id]);

    if (isLoading) {
        return <div className="loaderContainer">
          <CircularProgress color="inherit" />
        </div>;
    }
    if (productData?.products?.length === 0) {
        return (
          <div className="not-ava-msg">
            <h6>
              Sorry, there are no products available in this category at the moment.
              Please check back later or explore other categories.
            </h6>
            <BsEmojiExpressionless/>
            <Button className="btn btn-blue btn-lg mt-2"><Link to={'/'}>Back</Link></Button>
          </div>
        );
      }
    return (
        <>
            <section className="product_Listing_Page">
                <div className="container">
                    <div className="productListing w-100">

                        <div className="content_right">

                            <div className="productListing-data">
                                {
                                    isLoading === true ?
                                        <div className="d-flex align-items-center justify-content-center">
                                            <CircularProgress color="inherit" />
                                        </div>
                                        :

                                        <>
                                            {
                                                productData?.products?.map((item, index) => {
                                                    return (
                                                        <ProductItem key={index} itemView={productView} item={item} />
                                                    )
                                                })
                                            }
                                        </>

                                }



                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* {
                context.windowWidth < 992 &&
                <>
                    {
                        context.isOpenNav === false &&
                        <div className="fixedBtn row">
                            <div className="col">
                                <Button className='btn-blue bg-red btn-lg btn-big' onClick={openFilters}>
                                    <FaFilter />
                                    {isOpenFilter === true ? 'Close Filters' : 'Open Filters'}

                                </Button>
                            </div>
                        </div>
                    }
                </>

            } */}


        </>
    )
}

export default Listing2;